<template>

  <v-bottom-navigation dark shift fixed>
    <v-btn to="/time_line">
      <span>Home</span>

      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn to="/search">
      <span>Search</span>

      <v-icon>mdi-web</v-icon>
    </v-btn>
    <v-btn>
      <span>Update</span>

      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn to="/messages">
      <span>Messsages</span>

      <v-icon>mdi-mail</v-icon>
    </v-btn>

    <v-btn to="/account">
      <span>Account</span>

      <v-icon>mdi-account-circle</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  data: () => ({ value: 1 }),

  computed: {
    color() {
      switch (this.value) {
        case 0:
          return "blue-grey";
        case 1:
          return "teal";
        case 2:
          return "brown";
        case 3:
          return "indigo";
        default:
          return "blue-grey";
      }
    },
  },
};
</script>